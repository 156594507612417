import Layout from "./common/layout"
import Head from "./common/Head"
import Integrated from "./product/Integrated/Integrated"
import { useFormatter } from "helpers/i18n"

const ProductIntegrated = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "O2O Order Management System to Enable Omni-channel Retail "
            : "Hệ thống quản lý đơn hàng O2O hướng tới bán lẻ đa kênh"
        }
        description={
          currentLocale === "en"
            ? "OctoPOS, a system that consolidates shopper demand across multi e-commerce platforms then processes and offers a cost-effective delivery solution."
            : "OctoPOS là hệ thống tổng hợp nhu cầu người mua trên nhiều nền tảng thương mại điện tử, xử lý, cung cấp giải pháp tiết kiệm chi phí và thời gian giao hàng."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-2-meta.png"
        }
      />
      <Layout>
        <Integrated />
      </Layout>
    </>
  )
}

export default ProductIntegrated
